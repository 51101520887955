<template>
  <div class='tissue' v-loading="loading">
    <div class='globle_border'>
      <div class="search">
        <el-input v-model="search" placeholder="请输入部门名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增部门</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" row-key="departmentId" max-height="540" :tree-props="{ children: 'children' }">
          <el-table-column prop="departmentName" label="部门名称"></el-table-column>
          <el-table-column prop="departmentHead" label="部门负责人"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="260">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleAddRow(scope.$index, scope.row)">新增</el-button>
              <el-button class="btn" type="" size="small" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog :title="isAdd ? '新增' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <!-- 添加 -->
      <el-form v-if="isAdd" :model="addForm" :rules="rules" ref="addForm" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门名称" prop="departmentName">
              <el-input v-model="addForm.departmentName" placeholder="请输入部门名称"></el-input>
            </el-form-item>
            <el-form-item label="上级部门" prop="parentId">
              <el-cascader v-model="addForm.parentId" :options="tableData" :props="{ expandTrigger: 'hover', value: 'departmentId', label: 'departmentName', checkStrictly: true }" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门负责人" prop="departmentHead">
              <el-input v-model="addForm.departmentHead" placeholder="请输入部门负责人"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 编辑 -->
      <el-form v-else :model="editForm" :rules="rules" ref="editForm" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="部门名称" prop="departmentName">
              <el-input v-model="editForm.departmentName" placeholder="请输入部门名称"></el-input>
            </el-form-item>
            <el-form-item label="上级部门" prop="parentId">
              <el-cascader v-model="editForm.parentId" :options="tableData" :props="{ expandTrigger: 'hover', value: 'departmentId', label: 'departmentName', checkStrictly: true }" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门负责人" prop="departmentHead">
              <el-input v-model="editForm.departmentHead" placeholder="请输入部门负责人"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm(isAdd ? 'addForm' : 'editForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      submitLoding: false,
      search: '',
      tableData: [],
      dialogVisible: false,
      isAdd: true,
      editForm: {},
      addForm: {
        parentId: '0',
        departmentId: '',
        departmentHead: '',
        departmentName: '',
      },
      rules: {
        departmentName: [{ required: true, message: '请输入部门名称', trigger: 'submit' }],
        departmentHead: [{ required: true, message: '请输入部门负责人', trigger: 'submit' }],
      },
    }
  },
  created () {
    this.getSysDepartmentList()
  },
  methods: {
    // 部门列表
    getSysDepartmentList () {
      this.loading = true
      this.$axios.get(this.$api.getSysDepartmentList, {
        params: {
          departmentName: this.search
        }
      }).then((res) => {
        this.tableData = res.data.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 搜索
    onSearch () {
      this.getSysDepartmentList()
    },
    // 新增
    handleAdd () {
      this.isAdd = true
      this.addForm = {
        parentId: '',
        departmentHead: '',
        departmentName: '',
      }
      this.dialogVisible = true
    },
    // 新增指定子菜单
    handleAddRow (index, row) {
      this.isAdd = true
      this.addForm = {
        parentId: '',
        departmentHead: '',
        departmentName: '',
      }
      this.addForm.parentId = row.departmentId
      this.dialogVisible = true
    },
    // 修改
    handleEdit (index, row) {
      this.isAdd = false
      this.editForm = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(`${this.$api.deleteSysDepartment}/${row.departmentId}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getSysDepartmentList()
          })
      }).catch(() => { });
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false
      this.resetForm()
    },
    // 切换上级菜单
    changeCascader (arr) {
      // 截取arr最后一个元素
      if (this.isAdd) {
        if (arr.length > 0) {
          this.addForm.departmentId = arr[arr.length - 1]
        } else {
          this.addForm.departmentId = '0'
        }
      } else {
        if (arr.length > 0) {
          this.editForm.parentId = arr[arr.length - 1]
        } else {
          this.editForm.parentId = '0'
        }
      }
    },
    // 提交表单
    submitForm (formName) {
      this.submitLoding = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            let parentId = ''
            if (Array.isArray(this.addForm.parentId)) {
              parentId = this.addForm.parentId[0]
            } else {
              parentId = this.addForm.parentId
            }
            this.$axios.post(this.$api.addSysDepartment, {
              parentId: parentId,
              departmentHead: this.addForm.departmentHead,
              departmentName: this.addForm.departmentName,
            }).then((res) => {
              this.$message.success(res.data.desc);
              this.getSysDepartmentList()
              this.submitLoding = false
              this.dialogVisible = false
              this.resetForm()
            })
          } else {
            this.$axios.put(this.$api.updateSysDepartment, {
              departmentId: this.editForm.departmentId,
              parentId: this.editForm.parentId,
              departmentHead: this.editForm.departmentHead,
              departmentName: this.editForm.departmentName,
            }).then((res) => {
              this.$message.success(res.data.desc);
              this.getSysDepartmentList()
              this.submitLoding = false
              this.dialogVisible = false
              this.resetForm()
            })
          }
        } else {
          this.submitLoding = false
          this.$message.error('提交失败');
          return false;
        }
      });
    },
    // 重置校验
    resetForm () {
      if (this.isAdd) {
        this.$refs.addForm.resetFields()
      } else {
        this.$refs.editForm.resetFields()
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.tissue {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  ::v-deep .el-radio__inner {
    width: 16px;
    height: 16px;
  }

  ::v-deep .el-radio__label {
    font-size: 16px;
  }

  .parent_menu {

    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 736px;
    }
  }

  ::v-deep .el-input-number {
    width: 255px;
  }
}
</style>
